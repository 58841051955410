.info{
    margin-left: 1rem;
    padding: 3rem 6rem;
}
.info h1{
    font-size: 5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    line-height: 5rem;
}
.info p{
    width: 75%;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}
.info .descargadossier{
    margin-bottom: 2rem;
    margin-top:0.5rem;
}
.info .descargadossier a{
    background-color: black;
    color: white;
    padding: 0.5rem;
}
@media (max-width: 1500px) {
    .info p{
        width: 85%;
        font-size: 1rem;
    }
}
@media (max-width: 1200px) {
    .info h1{
        font-size: 4rem;
        line-height: 4.8rem;
    }
    .info p{
        width: 95%;
        font-size: 0.9rem;
    }
}
@media (max-width: 1050px) {  
    .info{
        padding: 0 5rem;
        margin-bottom: 2rem;
        margin-left: 0;
    }
    .info h1{
        font-size: 3rem;
        line-height: 3rem;
    }
}
@media (max-width: 650px) {  
    .info{
        padding: 0;
    }
    .info p{
        width: 100%;
    }
}