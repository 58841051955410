.roaming{
    margin: 5rem 2rem 2rem 2rem;
}
.content{
    display: grid;
    grid-template-columns: 20rem auto;
}
@media (max-width: 1050px) {  
    .content{
        grid-template-columns: auto;
    }
}