.aboutitem h2 {
    font-size: 1.2rem;
    border-bottom: 1px solid black;
    padding: 0.5rem 0;
}

.aboutitem button {
    padding: 0.5rem 0;
    font-size: 1.2rem;
    background-color: white;
}

.aboutitem p {
    margin-bottom: 1rem;
}

.aboutitem button:hover {
    cursor: pointer;
}

.cv {
    display: none;
}

.cv_open {
    display: block;
}

@media (max-width: 900px) {
    .aboutitem h2 {
        font-size: 1.2rem;
    }

    .aboutitem button {
        font-size: 1rem;
    }
}