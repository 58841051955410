.cardmodal{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color:rgba(0,0,0,0.8);
    z-index: 9999;
}
.cardm{
    position: relative;
    max-width: 30%;
    padding:2rem;
    background-color: white;
}
.cardm_close{
    position: absolute;
    top: -1.8rem;
    right: -1.8rem;
    color:white;
}
.cardm_close span{
    cursor: pointer;
    font-size: 2rem;
}
.cardm img{
    margin-bottom: 0.5rem;
}
.cardm_resumen{
    margin-bottom: 4rem;
}
.cardm_texto{
    padding: 0;
}
.cardm_texto h2{
    font-size: 1.2rem;
    margin-bottom:1.5rem;
    text-transform: uppercase;
    font-weight: 500;
}
.cardm_resumen_date{
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.cardm_enlaces a{
    display: block;
    font-weight: 700;
    text-decoration: underline;
}

@media (max-width: 1500px) {
    .cardm{
        max-width: 40%;
    }
}
@media (max-width: 1080px) {
    .cardm{
        max-width: 45%;
    }
}
@media (max-width: 800px) {
    .cardm{
        max-width: 60%;
        overflow-y: scroll;
        max-height: 95%;
    }
    .cardm_close{
        top: 0.2rem;
        right: 0.2rem;
        color:black;
    }
    .cardm_close span{
        font-size: 1.7rem;
    }
}
@media (max-width: 600px) {
    .cardm{
        max-width: 80%;
    }
}