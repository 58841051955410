.about_texto {
    margin: 4rem 10rem;
}

.about_texto h1 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
}

.about_texto p {
    margin-bottom: 1rem;
    font-size: 1.05rem;
}

.aboutlist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem;
    margin: 4rem 10rem;
}

.contact {
    margin: 4rem 10rem;
}

.contact h4 {
    margin-bottom: .25rem;
}

.contact a {
    display: block;
}

@media (max-width: 1200px) {
    .aboutlist {
        grid-template-columns: 1fr 1fr 1fr;
        margin: 4rem;
    }

    .about_texto {
        margin: 4rem;
    }
}

@media (max-width: 900px) {
    .aboutlist {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .aboutlist {
        grid-template-columns: 1fr;
    }
}