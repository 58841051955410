.presslist{
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap:2rem;
    margin: 5rem;
}
@media (max-width: 1500px) {
    .presslist{
        margin: 3rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (max-width: 1200px) {
    .presslist{
        margin: 3rem;
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 900px) {
    .presslist{
        margin: 2rem;
        grid-template-columns: 1fr;
    }
}