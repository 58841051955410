.bookform {
    width: 30%;
    padding-right: 6%;
}
.bookform h2{
    font-size: 1.5rem;
    font-weight: 400;
    color: #0f9bd6;
}

.bookform form{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}
.bookform form label{
    margin-left: 0.2rem;
    font-size: 0.9rem;
}
.bookform form input{
    margin-bottom: 0.5rem;
    padding: 0.4rem;
    border: 1px solid rgb(190, 190, 190);
    border-radius: 5px;
}
.bookform form select{
    margin-bottom: 0.5rem;
    padding: 0.3rem;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 5px;
}
.bookform form textarea{
    margin-bottom: 0.5rem;
    padding: 0.3rem;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 5px;
}
.bookform form input[type="submit"]{
    margin-bottom: 0.5rem;
    padding: 0.8rem;
    background-color: #0f9bd6;
    color: white;
    text-transform: uppercase;
}
.bookform form a{
    margin-bottom: 0.5rem;
    padding: 0.8rem;
    background-color: #0f9bd6;
    color: white;
    text-transform: uppercase;

    font-size: 0.8rem;
    text-align: center;
}

@media (max-width: 900px) {
    .bookform {
        width: 40%;
    }
}
@media (max-width: 700px) {
    .bookform {
      width: 100%;
      margin-bottom: 2rem;

      padding-right: 0;
    }
}