.maincard {
    display: grid;
    grid-template-columns: 55vh auto;
    background-color: rgb(207, 216, 220);
    margin: 0 1rem;
    margin-bottom: 1.5rem;
}

.maincard:last-of-type {
    margin-bottom: 0;
}

.imagen {
    height: 55vh;
    min-height: 100%;
}

.imagen img {
    height: 100%;
    object-fit: cover;
}

.data {
    grid-column: 2;
    color: #1c7480;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    margin: 3rem;
    font-size: 1.18rem;
}

.data_text {
    margin-bottom: 5rem;
}

.data span {
    position: absolute;
    bottom: 0;
    right: 5rem;
}

.data h2 {
    font-size: 4.5rem;
    margin-bottom: 3rem;
    padding-right: 5rem;
}

.data span:hover {
    cursor: pointer;
}

@media (max-width: 1140px) {
    .data h2 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    .data p {
        font-size: 0.9rem;
    }

    .data span {
        font-size: 0.9rem;
        right: 0rem;
    }
}

@media (max-width: 1080px) {
    .maincard {
        grid-template-columns: 30vh auto;
    }

    .imagen {
        height: 30vh;
    }

    .data h2 {
        padding-right: 0;
    }
}

@media (max-width: 800px) {
    .maincard {
        grid-template-columns: 1fr;
        margin: 0;
        margin-bottom: 1.5rem;
    }

    .maincard:last-of-type {
        margin-bottom: 0;
    }

    .imagen {
        height: 40vh;
    }

    .data {
        grid-column: 1;
        margin: 2rem;
    }

    .data h2 {
        margin-bottom: 2rem;
    }

    .data_text {
        margin-bottom: 0;
    }

    .data span {
        position: relative;
        display: block;
        margin-top: 2rem;
    }
}