.rcardts {
    position: relative;

    grid-column: span;

    padding: 0.5rem;
    box-shadow: 5px 5px 5px grey;
}

.rcardts img {
    margin-bottom: 0.5rem;
}

.rcardts_resumen {
    margin-bottom: 4rem;
}

.rcardts_texto h2 {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
}

.rcardts_resumen_date {
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.rcardts_enlaces {
    position: absolute;
    bottom: 1.5rem;
}

.rcardts_enlaces a {
    display: block;
    font-weight: 700;
    text-decoration: underline;
}