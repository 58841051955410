.rLoadmore {
    text-align: center;
    width: 75%;
}

.rLoadmore button {
    background-color: #FFBC0B;
    padding: 1rem 3rem;
    margin-bottom: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}

@media (max-width: 1500px) {
    .rLoadmore {
        width: 85%;
    }
}

@media (max-width: 1200px) {
    .rLoadmore {
        width: 95%;
    }
}

@media (max-width: 650px) {
    .rLoadmore {
        width: 100%;
    }
}