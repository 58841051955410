.itinerancias{
    border-right: 2px solid rgb(18, 18, 19);
}
.itinerancias h2{
    margin-bottom: 1rem;
}
.itinerancia{
    margin-bottom: 1rem;
    border-left: 4px solid rgb(18, 18, 19);
    padding-left: 1rem;
}
.itinerancia:hover{
    border-left: 4px solid rgb(173, 28, 28);
    cursor: pointer;
}
.itinerancia p{
    margin-left: 1rem;
}
@media (max-width: 1200px) {  
    .itinerancia p{
        font-size: 0.9rem;
    }
}
@media (max-width: 1050px) {  
    .itinerancias{
        order: 2;
        padding: 0 5rem;
        border: none;
    }
    .lista{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap:1rem;
    }
    .itinerancia{
        margin-right: 1rem;
        border: 4px solid black;
        padding: 1.5rem;
    }
    .itinerancia:hover{
        border: 4px solid rgb(173, 28, 28);
    }
    .itinerancia h3{
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }
    .itinerancia p{
        margin-left: 0;
        font-size: 1rem;
    }
}
@media (max-width: 750px) {  
    .itinerancia p{
        font-size: 0.9rem;
    }
    .lista{
        grid-template-columns: 1fr;
    }
}
@media (max-width: 650px) {  
    .itinerancias{
        padding: 0;
    }
    .itinerancia h3{
        font-size: 1.5rem;
    }
    .itinerancia p{
        font-size: 1rem;
    }
}