.photomodal {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}

.image_container {
    max-width: 90%;
}

.image_container img {
    max-height: 90vh;
    object-fit: contain;
}

.photo_ctrl {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.photo_ctrl a {
    user-select: none;
    font-size: 3rem;
    color: white;
    margin: 1rem;
}

.photo_ctrl span {
    cursor: pointer;
    user-select: none;
    font-size: 3rem;
    color: white;
    margin: 1rem;
}


@media (max-width: 900px) {
    .photomodal img {
        width: 600px;
    }
}

@media (max-width: 650px) {
    .photomodal img {
        width: 350px;
    }
}

@media (max-width: 360px) {
    .photomodal img {
        width: 250px;
    }
}