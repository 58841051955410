.loadmore {
    text-align: center;
}

.loadmore button {
    background-color: #FFBC0B;
    padding: 1rem 3rem;
    margin-bottom: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}