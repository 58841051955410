.info_roaming{
    margin-left: 1rem;
    padding: 3rem 6rem;
}
.info_roaming h1{
    font-size: 5rem;
    text-transform: uppercase;
    line-height: 5rem;
    margin-bottom: -0.2rem;
}
.info_roaming h2{
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 2rem;
}
.info_roaming p{
    width: 75%;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}
.info_roaming p:first-of-type{
    margin-bottom: 0;
}
@media (max-width: 1500px) {
    .info_roaming p{
        width: 85%;
        font-size: 1rem;
    }
}
@media (max-width: 1200px) {
    .info_roaming h1{
        font-size: 4rem;
        line-height: 4.8rem;
    }
    .info_roaming p{
        width: 95%;
        font-size: 0.9rem;
    }
}
@media (max-width: 1050px) {  
    .info_roaming{
        padding: 0 5rem;
        margin-bottom: 2rem;
        margin-left: 0;
    }
    .info_roaming h1{
        font-size: 3rem;
        line-height: 3rem;
    }
}
@media (max-width: 650px) {  
    .info_roaming{
        padding: 0;
    }
    .info_roaming p{
        width: 100%;
    }
}