.menu{
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 13rem 2rem;
    grid-gap:1rem;

    margin-bottom: 2rem;
}
.menu_responsive{
    display: none;
    position: absolute;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    z-index: 2;
}
.menu_responsive li{
    margin: 25px;
}
.menu_burger{
    display: none;
    position: relative;
    transform: rotate(-90deg);
    letter-spacing: 0.3rem;
    font-size: 1.5rem;    
}
.menu_burger:hover{
    cursor: pointer;
}
.logo {
    grid-column: 2;
    justify-self:center;
    align-self: end;

    width: 20rem;
    height: auto;
}
.menu ul{
    display: flex;
    grid-auto-columns: 1fr;

    grid-column:2;
    grid-row:2;
    justify-self:center;

    border-top: 2px solid black;
}
.menu ul li{
    justify-self:center;
    margin:1rem;
}
.menu ul li a:hover{
    border-bottom: 2px solid black;
}
.selected{
    border-bottom: 2px solid black;
}


.lang{
    display: flex;
    position: absolute;
    right: 1rem;
    top: 1rem;
}
.lang p:hover{
    cursor: pointer;
}
.lang p:first-of-type:after{
    margin-right: 0.5rem;
}
.lang p:first-of-type:after{
    margin-left: 0.5rem;
    content: "||";
}
.lang p:nth-of-type(2):after{
    margin-right: 0.5rem;
}
.lang p:nth-of-type(2):after{
    margin-left: 0.5rem;
    content: "||";
}
.lang_responsive{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.lang_responsive p:hover{
    cursor: pointer;
}
.lang_responsive p:first-of-type:after{
    margin-right: 0.5rem;
}
.lang_responsive p:first-of-type:after{
    margin-left: 0.5rem;
    content: "||";
}
.lang_responsive p:nth-of-type(2):after{
    margin-right: 0.5rem;
}
.lang_responsive p:nth-of-type(2):after{
    margin-left: 0.5rem;
    content: "||";
}

.rrss{
    display: flex;
    position: absolute;
    left: 1rem;
    top: 1rem;
}
.rrss img{
    margin-right: 0.5rem;
    width: 30px;
}
.rrss_responsive{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.rrss_responsive img{
    width: 30px;
    margin: 0 0.25rem
}

@media (max-width: 700px){
    .menu{
        display: grid;
        grid-template-columns: 5rem auto;
          grid-template-rows:1fr;
          grid-gap:1rem;

          padding: 1rem;
          border-bottom: 1px solid black;
          margin-bottom: 0;
    }
    .menu_burger{
        display: block;

        grid-column:2;
        grid-row:1;
        justify-self:end;
        align-self: center;
    }
    .logo{
        grid-column: 1;
        justify-self:center;
        align-self: center;

        width: 5rem;
        height: auto;
    }
    .menu ul{
        display: none;
    }
    .lang{
        display: none;
    }
    .rrss{
        display: none;
    }
}