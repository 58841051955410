.workitem{
    position: relative;
}
.workitem img{
    object-fit: cover;
}
.workitem h2{
    font-size: 1.5rem;
    background-color: rgb(207, 216, 220);
    color: #1c7480;
    text-transform: uppercase;
    padding: 0.5rem;
}
@media (max-width: 950px) {
    .workitem h2{
        font-size: 1rem;
    }
}