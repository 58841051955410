.galeria{
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 3rem;
}
.galeria img{
    height: 10rem;
    object-fit: cover;
}
.galeria:hover{
    cursor: pointer;
}
@media (max-width: 1500px) {
    .galeria{
        width: 85%;
    }
}
@media (max-width: 1200px) {
    .galeria{
        width: 95%;
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 650px) {
    .galeria{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .galeria img{
        height: 15rem;
    }
}