.roamingActivities {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin: 2rem 0;
    grid-gap: 2rem;
    width: 75%;
}

@media (max-width: 1500px) {
    .roamingActivities {
        width: 85%;
    }

}

@media (max-width: 1200px) {
    .roamingActivities {
        width: 95%;
    }
}

@media (max-width: 650px) {
    .roamingActivities {
        width: 100%;
    }
}