.worklist{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap:1.5rem;
    margin: 10rem;
}
@media (max-width: 1200px) {
    .worklist{
        margin: 5rem;
    }
}
@media (max-width: 950px) {
    .worklist{
        grid-template-columns: 1fr;
        margin: 2rem;
    }
}
