.books {
  margin: 5rem 2rem 2rem 2rem;
}
.books_content {
  display: flex;
  margin-bottom: 5rem;
}
.books_text {
  width: 70%;
}
.books_text h1 {
  margin-bottom: 1rem;
}
.subvencion{
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
.subvencion img{
  width: 25%;
  object-fit: contain;
}
.subvencion p{
  margin-left: 1rem;
  font-weight: 700;
  font-size: 1.25rem;
}
@media (max-width: 1200px) {
  .subvencion img{
    width: 50%;
  }
}
@media (max-width: 900px) {
  .books_text {
    width: 60%;
  }
  .subvencion p{
    font-size: 1rem;
  }
}
@media (max-width: 700px) {
  .subvencion{
    flex-direction: column;
    align-items: center;
  }
  .subvencion img{
    width: 100%;
    margin-bottom: 1rem;
  }
  .subvencion p{
    font-size: 1.2rem;
  }
  .books_content {
    display: block;
  }
  .books_text {
    width: 100%;
  }
  .books_text h1 {
    display: none;
  }
}
