.card{
    position: relative;

    grid-column: span;

    padding:0.5rem;
    box-shadow:5px 5px 5px grey;
}
.card img{
    margin-bottom: 0.5rem;
}
.card_resumen{
    margin-bottom: 4rem;
}
.card_texto h2{
    font-size: 1.2rem;
    margin-bottom:1.5rem;
    text-transform: uppercase;
    font-weight: 500;
}
.card_resumen_date{
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.card_enlaces{
    position: absolute;
    bottom: 1.5rem;
}
.card_enlaces a{
    display: block;
    font-weight: 700;
    text-decoration: underline;
}